.bronze {
  border: 5px solid #dfaa5b !important;
}

.silver {
  border: 5px solid #d3d3d3 !important;
}

.gold {
  border: 5px solid #f5d357 !important;
}